import { PREFIX } from '../constant';
import { cx } from '../utils';

const Heading = ({ as, size = 'md', children, ...props }) => {
  const Component = as || 'h1';

  const headingSize = size || 'md';

  return (
    <Component
      {...props}
      className={cx(
        `${PREFIX}__heading ${PREFIX}__heading--size-${headingSize}`,
        props.className
      )}
    >
      {children}
    </Component>
  );
};

export default Heading;
