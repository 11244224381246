import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { PREFIX } from '../constant';
import Heading from '../layout/heading';

export const ModalCloseButton = ({ onClick }) => {
  return (
    <button className={`${PREFIX}__modal__close`} onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7948 14.9687C14.1863 15.3582 14.8195 15.3565 15.209 14.9649C15.5985 14.5734 15.5968 13.9402 15.2052 13.5507L13.7948 14.9687ZM2.20523 0.619393C1.81368 0.229904 1.18051 0.23158 0.791023 0.623137C0.401535 1.01469 0.403211 1.64786 0.794768 2.03735L2.20523 0.619393ZM15.2052 2.03735C15.5968 1.64786 15.5985 1.01469 15.209 0.623137C14.8195 0.23158 14.1863 0.229904 13.7948 0.619393L15.2052 2.03735ZM0.794768 13.5507C0.403211 13.9402 0.401535 14.5734 0.791023 14.9649C1.18051 15.3565 1.81368 15.3582 2.20523 14.9687L0.794768 13.5507ZM15.2052 13.5507L2.20523 0.619393L0.794768 2.03735L13.7948 14.9687L15.2052 13.5507ZM13.7948 0.619393L0.794768 13.5507L2.20523 14.9687L15.2052 2.03735L13.7948 0.619393Z"
          fill="#0040D8"
        />
      </svg>
    </button>
  );
};

export function Modal({
  show,
  wide = false,
  small = false,
  enableClose = false,
  disableEsc = false,
  onClose,
  title,
  titleClassName = '',
  children,
  dialogClassName = ''
}) {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className={`${PREFIX}__modal`}
        onClose={disableEsc ? () => {} : onClose}
        style={{ zIndex: 1500 }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed ${PREFIX}__modal--backdrop inset-0`} />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="d-flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${PREFIX}__modal__panel${
                  wide ? '_wide' : small ? '_small' : ''
                } ${dialogClassName} w-full transform rounded-2xl bg-white  text-left align-middle shadow-xl transition-all`}
              >
                {enableClose && (
                  <div
                    className={`d-flex justify-content-end ${PREFIX}__modal__close__container ${
                      small ? 'me-2 mt-2' : ''
                    }`}
                  >
                    <ModalCloseButton onClick={onClose} />
                  </div>
                )}
                <Dialog.Title className="text-center">
                  <Heading className={`d-none d-md-block ${titleClassName}`} as="span" size="md">
                    {title}
                  </Heading>
                </Dialog.Title>

                <hr className={`d-none d-md-block ${PREFIX}__modal__splitter`} />

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
