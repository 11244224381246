import React, { useState } from 'react';
import { Button } from '../../core/button';
import { EMAIL_PLACEHOLDER } from '../../core/constant';
import { Modal } from '../../core/modal/modal';
import Image from "next/legacy/image";
import Router from 'next/router';

import newsletterIllustration from "/public/assets/images/newsletter-figure.png";
import newsletterNotValidIllustration from "/public/assets/images/newsletter-not-valid-figure.svg";

import api from 'api';

import { NewsletterModel } from 'gen/openapi';

const Newsletter = () => {
  const [validNewsletterModalOpen, setValidNewsletterModalOpen] =
    useState<any>(false);
  const [newsletterErrorModalOpen, setNewsletterErrorModalOpen] =
    useState<any>(false);
  const [email, setEmail] = useState<any>('');

  const newsletterInitialValues: NewsletterModel = {
    email: '',
  };


  const emailValidation = (email) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) return true;
    else false;
  };
  const emailSubscribe = async (values: NewsletterModel) => {
    const isValidEmail = emailValidation(email);
    if (isValidEmail) {

      try {
        newsletterInitialValues.email=email;
        const response = await api.request({
          api: 'NewsletterApi',
          method: 'apiAccountNewsletterPost',
          params: [
            newsletterInitialValues
          ],
        });

        if (response.success) {
          setValidNewsletterModalOpen(true);
        } else {

          import('../../../toolkit/toaster')
              .then(d => d.Errorful)
              .then(t => t({ message: response.message }));
        }
      } catch (error) {

        import('../../../toolkit/toaster')
            .then(d => d.Errorful)
            .then(t => t({ message: error.message, exception: error }));
      }
    } else {
      setNewsletterErrorModalOpen(true);
    }
  };

  return (
    <>
      <Modal
        show={validNewsletterModalOpen}
        onClose={() => setValidNewsletterModalOpen(false)}
        title="You Successfully Subscribed Our Newsletter!"
        enableClose
      >
        <div className="confirm-password-modal d-flex flex-column m-auto align-items-center">
          <div className="position-relative confirm-password-modal-image">
            <div className="landing-radial" />
            <div className="next-image">
              <Image src={newsletterIllustration} width={187} height={229} alt="newsletter-figure" />
            </div>
          </div>

          <Button
            className="confirm-password-modal-button w-100"
            size="lg"
            onClick={() => Router.push({ pathname: '/workspace' }, '/workspace')}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        show={newsletterErrorModalOpen}
        onClose={() => setNewsletterErrorModalOpen(false)}
        title="Email is Not Valid"
        enableClose
      >
        <div className="confirm-password-modal d-flex flex-column m-auto align-items-center">
          <div className="position-relative confirm-password-modal-image">
            <div className="landing-radial" />
            <div className="next-image">
              <Image src={newsletterNotValidIllustration} width={187} height={229} alt="newsletter-not-valid-figure" />
            </div>
          </div>

          <Button
            className="confirm-password-modal-button w-100"
            size="lg"
            onClick={() => setNewsletterErrorModalOpen(false)}
          >
            Try Again
          </Button>
        </div>
      </Modal>
      <section className="newsletter d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center newsletter-container">
          <span className="newsletter-title">Join Our Newsletter</span>
          <div className="newsletter-sub-container">
            <input
              onChange={(value) => setEmail(value.target.value)}
              placeholder={EMAIL_PLACEHOLDER}
              type="email"
            />
            <Button onClick={emailSubscribe} type="submit">
              Subscribe
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export { Newsletter };
